import React from "react";

const PrivacyPolicy = () => {
    console.log("Privacy Policy component rendered");
    return (
    <div>
      <h1>Privacy Policy</h1>
      <p>Your privacy is important to us. This page outlines how we collect, use, and protect your data...</p>
    </div>
    );
};
export default PrivacyPolicy;