import React, { useState, useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import axios from 'axios';
import '../styles/Contact.css';

const Contact = () => {
  const { language } = useContext(LanguageContext);

  const validationErrors = {
    'en': {
      nameError : 'Name is required',
      'emailError' : 'Email is required',
      'invalidEmailError' : 'Invalid email',
      'subjectError' : 'Subject is required',
      'messageError' : 'Message is required'
    },
    'bg': {
      'nameError' : 'Полето е задължително',
      'emailError' : 'Полето е задължително',
      'invalidEmailError' : 'Невалиден имейл',
      'subjectError' : 'Полето е задължително',
      'messageError' : 'Полето е задължително'
    }
  }

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: 'Business Collaboration', // Default subject
    customSubject: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    customSubject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Reset the error when the user types
    setErrors({ ...errors, [name]: '' });
  };

  const handleSubjectChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      subject: value,
      customSubject: value === 'Other' ? formData.customSubject : '',
    });
  };

  const validateForm = () => {
    let formIsValid = true;
    let newErrors = { name: '', email: '', customSubject: '', message: '' };

    if (!formData.name.trim()) {
      newErrors.name = validationErrors[language].nameError;
      formIsValid = false;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@(gmail\.com|abv\.bg|yahoo\.com)$/;

    if (!formData.email.trim()) {
      newErrors.email = validationErrors[language].emailError;
      formIsValid = false;
    } else if (!emailRegex.test(formData.email)) { // !/\S+@\S+\.\S+/
      newErrors.email = validationErrors[language].invalidEmailError;
      formIsValid = false;
    }

     // Validate the subject field
    if (formData.subject === 'Other' && !formData.customSubject.trim()) {
      newErrors.customSubject = validationErrors[language].subjectError;
      formIsValid = false;
    }

    if (!formData.message.trim()) {
      newErrors.message = validationErrors[language].messageError;
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const finalFormData = {
        ...formData,
        subject: formData.subject === 'Other' ? formData.customSubject : formData.subject,
      };

      if( language === 'en') {
        alert('Please wait!') 
      }  
      else {
        alert('Моля изчакайте!');
      }

      try {
        const response = await axios({
          method: 'POST',
          url: 'https://rokyuto-1.onrender.com/api/send-email',
          headers: {
            'Content-Type': 'application/json',
          },
          data: finalFormData,
        });

        if (response.status === 200) {
          if( language === 'en') {
            alert('Email sent successfully!') 
          }  
          else {
            alert('Имейлът е изпратен успешно!');
          }
          // Clear the form
          setFormData({
            name: '',
            email: '',
            subject: 'Business Collaboration',
            customSubject: '',
            message: ''
          });
        } else {
          if( language === 'en') {
            alert('Failed to send email. Please try again later.')
          }
          else{
            alert('Неуспешно изпращане на имейл. Моля опитайте отново по-късно.');
          }
        }
      } catch (error) {
        console.error('Error:', error);
        if( language === 'en') {
          alert('An error occurred. Please try again later.')
        }
        else {
          alert('Възникна грешка. Моля опитайте отново по-късно.');
        }
      }
    }
  };

  return (
    <div className="contact-form-container">
      <form onSubmit={handleSubmit} className="contact-form">
        <h1>{ language === 'en' ? 'Send me a message' : 'Изпратете съобщение' }</h1>
        <div className="form-group">
          <label htmlFor="name">{ language === 'en' ? 'Your Name' : 'Вашето име' }</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            autoComplete="off"
            className={errors.name ? 'error' : ''}
          />
          {errors.name && <span className="error-message">{errors.name}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="email">{ language === 'en' ? 'Your Email' : 'Вашият имейл' }</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            autoComplete="off"
            className={errors.email ? 'error' : ''}
          />
          {errors.email && <span className="error-message">{errors.email}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="subject">{ language === 'en' ? 'Subject' : 'Тема на съобщението' }</label>
          <select
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleSubjectChange}
            className="custom-select"
          >
            <option value="Business Collaboration">{ language === 'en' ? 'Business Collaboration' : 'Бизнес сътрудничество' }</option>
            <option value="Consultation">{ language === 'en' ? 'Consultation' : 'Консултация' }</option>
            <option value="Other">{ language === 'en' ? 'Other' : 'Друго' }</option>
          </select>
          {formData.subject === 'Other' && (
            <>
              <input
                type="text"
                id="customSubject"
                name="customSubject"
                value={formData.customSubject}
                onChange={handleChange}
                placeholder={ language === 'en' ? "Enter your subject" : "Въведете вашата тема на съобщение" }
                autoComplete="off"
                className="custom-subject-input"
              />
              {errors.customSubject && <span className="error-message">{errors.customSubject}</span>}
            </>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="message">{ language === 'en' ? 'Your Message' : 'Вашето съобщение' }</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            autoComplete="off"
            className={errors.message ? 'error' : ''}
          ></textarea>
          {errors.message && <span className="error-message">{errors.message}</span>}
        </div>

        <button type="submit" className="submit-btn">{ language === 'en' ? 'Send message' : 'Изпратете съобщението' }</button>
      </form>
    </div>
  );
};

export default Contact;
