import React, {useState, useEffect} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/Slideshow.css';
import leftArrow from '../assets/icons/arrow-left.svg';
import rightArrow from '../assets/icons/arrow-right.svg';
import closeIcon from '../assets/icons/close_Masked.svg';

// Custom Arrow Components
const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-prev-arrow`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={leftArrow} alt='Previous'/>
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-next-arrow`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={rightArrow} alt="Next" />
    </div>
  );
};

const Slideshow = ({ images }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [modalText, setModalText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0); // Track current image index in modal

  // Open the modal with the clicked image
  const openModal = (image, text) => {
    setModalImage(image);
    setModalText(text);
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage(null);
    setModalText('');
  };

  // Go to the next image
  const goToNextImage = () => {
    const nextIndex = (currentIndex + 1) % images.length; // Loop back to the start
    setModalImage(images[nextIndex].src);
    setModalText(images[nextIndex].text);
    setCurrentIndex(nextIndex);
  };

  // Go to the previous image
  const goToPrevImage = () => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length; // Loop to the end
    setModalImage(images[prevIndex].src);
    setModalText(images[prevIndex].text);
    setCurrentIndex(prevIndex);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isModalOpen]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <>
    <div className="slideshow-container">
      <Slider {...settings} className="slideshow">
        {images.map((image, index) => (
          <div key={index} className="slide-container">
            <img
              src={image.src}
              alt={`Slideshow ${index + 1}`}
              className="slideshow-image full-width-image"
              onClick={() => openModal(image.src, image.text)}
            />
          </div>
        ))}
      </Slider>
    </div>

    {isModalOpen && (
      <div className="modal">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className='modal-image-container'>
            <img src={modalImage} alt="Focused" className="focused-image" />
            <div className="modal-arrows">
              <CustomPrevArrow className="modal-prev-arrow" onClick={goToPrevImage}/>
              <CustomNextArrow className="modal-next-arrow"  onClick={goToNextImage}/>
            </div>
          </div>
          <p className="image-text">{modalText}</p>
          <button className="modal-close-button" onClick={closeModal}>
            <img src={closeIcon} alt="Close" />
          </button>
        </div>
      </div>
    )}
    </>
  );
};

export default Slideshow;
