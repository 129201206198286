import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/ProjectContainer.css';
import leftArrow from '../assets/icons/arrow-left.svg';
import rightArrow from '../assets/icons/arrow-right.svg';

// Custom Arrow Components
const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow-project-container custom-prev-arrow`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={leftArrow} alt='Previous'/>
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow-project-container custom-next-arrow`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={rightArrow} alt="Next" />
    </div>
  );
};

const ProjectContainer = ({ media, text, icon, href, bottomRightText }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  // Function to render either an image or video
  const renderMedia = (mediaItem, index) => {
    const isVideo = mediaItem.endsWith('.mp4') || mediaItem.endsWith('.webm');
    if (isVideo) {
      return (
        <div key={index} className="slide-container">
          <video
            controls
            className="slideshow-video project-video"
          >
            <source src={mediaItem} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    } else {
      return (
        <div key={index} className="slide-container">
          <img
            src={mediaItem}
            alt={`Project Media ${index + 1}`}
            className="slideshow-image project-image"
          />
        </div>
      );
    }
  };

  return (
    <div className="project-container">
      <div className="project-slideshow">
        <Slider {...settings} className="slideshow">
          {media.map((mediaItem, index) => renderMedia(mediaItem, index))}
        </Slider>
      </div>
      <div className="project-text">
        <h2><img src={icon[0]} alt='' className="text-image" /> {text[0]}</h2>
        <p><img src={icon[1]} alt='' className="long_text-image" /> {text[1]}</p>
        <p><img src={icon[2]} alt='' className="long_text-image" /> {text[2]}</p>
        <p><img src={icon[3]} alt='' className="text-image" /> {text[3]}</p>
        {href && icon[4] && (
          <a href={href} target="_blank" rel="noreferrer">
            <img src={icon[4]} alt="" className="text-image" /> {text[4]}
          </a>
        )}
        <div className="date">
          <p>{bottomRightText}</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectContainer;
