import React from 'react';
import '../styles/Footer.css';
import logo from '../assets/logo_long.png'; // Import the logo

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <img src={logo} alt="Logo" className="footer-logo-image" />
          <div className="footer-policy-terms">
            <a href="/terms-of-service">Terms of Service</a>
            <a href="/privacy-policy">Privacy Policy</a>
          </div>
        </div>
        <div className="footer-links">
          <div className="footer-links-column-1">
            <h3>View my work</h3>
            <a href="https://youtube.com/@rokyuto404?si=3-EgHq3mOoBRcM_w" target="_blank" rel="noopener noreferrer"><img src={'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/youtube.png'} className='linkImages' alt='' /> My YouTube</a>
            <a href="https://github.com/Rokyuto" target="_blank" rel="noopener noreferrer"><img src={'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/github.png'} className='linkImages' alt='' /> My GitHub</a>
            <a href="https://huggingface.co/Rokyuto" target="_blank" rel="noopener noreferrer"><img src={'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/hug.png'} className='linkImages' alt='' /> My HuggingFace</a>
          </div>
          <div className="footer-links-column-2">
            <h3>Contact</h3>
            <a href="https://www.linkedin.com/in/viktor-asenov-9511404v5/" target="_blank" rel="noopener noreferrer"><img src={'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/linkedin.png'} className='linkImages' alt='' /> LinkedIn</a>
            <a href="https://www.instagram.com/_viktor.asenov_/" target="_blank" rel="noopener noreferrer"><img src={'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/instagram.png'} className='linkImages' alt='' /> Instagram</a>
            <a href="https://www.facebook.com/share/To3zU6Fez1Lt1asF/" target="_blank" rel="noopener noreferrer"><img src={'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/facebook.png'} className='linkImages' alt='' /> Facebook</a>
          </div>
        </div>
      </div>
      <p>© Copyright 2024 Rokyuto. All Rights Reserved.</p>
    </footer>
  );
};

export default Footer;
