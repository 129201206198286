import React, { useState, useEffect, useMemo, useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import '../styles/AboutMe.css';
import image from '../assets/images/AboutMe/IMG_4822.jpg';

const AboutMe = () => {
  const birthDate = useMemo(() => new Date('2005-03-11'), []);
  const { language } = useContext(LanguageContext)

  const calculateAge = (birthDate) => {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  const [age, setAge] = useState(calculateAge(birthDate));

  useEffect(() => {
    setAge(calculateAge(birthDate));
  }, [birthDate]);

  return (
    <div className="about-me-wrapper">
      <div className="about-me-container">
        <div className="side-text-container">
          <div className="image-container">
            <img src={image} alt="Viktor Asenov" />
          </div>
          <div className="side-text-content">
            <h1>{language === 'en' ? 'About Me' : 'За Мен'}</h1>
            <p> 
              { language === 'en' 
              ? <>
                  Hello! I am <strong>Viktor Asenov</strong>, {age} years old, from Pleven, Bulgaria.
                </> 
             : <>
                Здравейте! Аз съм <strong>Виктор Асенов</strong>, на {age} години, от Плевен, България.
              </>
            }
            </p>
            <p style={{textIndent: '30px'}} >
              { language === 'en' 
                ? <>
                    Since childhood, I have been interested in computer games. Initially, I only wanted to play, but over time I started to get curious about game development. So, I told my uncle about my desire to create games and be like him (he's a Game Designer). That's when I began working with Unreal Engine back in the summer of 2019.
                  </>
                : <>
                    От детството си се интересувам от компютърни игри. Първоначално имах желание само да играя, но с времето започнах да се интересувам от това как се създават игри. Споделих на вуйчо ми желанието си да създавам игри и да съм като него (той е Game Designer). Така започнах да работя с Unreal Engine през лятото на 2019 г.
                  </>
              }            
            </p>
            <p style={{textIndent: '30px'}} >
              { language === 'en' 
                ? <>
                    At the start, I was struggling to make up my mind. There were questions like: <i>"Where I should start from?"</i>, <i>"How to create this in the most correct way?"</i>, so the first year was understanding what this engine offers and learning all kind of things - Different Blueprint Use-cases, Level Design, VFX effects, Widgets, and developing different functionalities, whithout aiming to create something completed as a product in the end. Then, from the second year, I found myself understanding more or less what is going on and it became easier to start <strong>challenging myself to create something whole</strong>. 
                  </>
                : <>
                    В началото беше доста трудно. Имаше въпроси като: <i>„Откъде трябва да започна?“</i>, <i>„Как да създам това по най-правилния начин?“</i>, така че първата година беше разбирането на това какво представлява и предлага този двигател и да науча всякакви неща могат да се правят с него - различни случаи на използване на Blueprint, дизайн на ниво, VFX ефекти, джаджи и разработване на различни функционалности, без да се стремя да създадм нещо завършено като продукт. След това, от втората година, открих, че разбирам повече какво се случва и стана по-лесно да започна да <strong>предизвиквам себе си да създам нещо цялостно</strong>.
                  </>
              }
            </p>
          </div>
        </div>
        <div className="full-width-text-container">
          <p style={{textIndent: '30px'}} >
            { language === 'en' 
              ? <>
                  Meanwhile, I enrolled in the <a href="https://uktc-bg.com/" target="_blank" rel='noreferrer'>National Vocational High School of Computer Technologies and Systems</a>, located in Pravets, where the students graduate with a professional qualification as a <i><q>System Programmer</q></i>. <strong>At the start, I was not interested in programming</strong>, like, my full attention was focussed in the Blueprints and Unreal Engine. But at <i>10th grade at school</i>, we started having more lectures and practical exercises of programming, and that was the moment, when <strong>I found myself entertained and being absorbed in the writing of code</strong>.
                </>
              : <>
                  Междувременно се записах в <a href="https://uktc-bg.com/" target="_blank" rel='noreferrer'>Национална професионална гимназия по компютърни технологии и системи</a>, която се намира в Правец. , където учениците завършват с придобиване на професионална квалификация <i><q>Системен програмист</q></i>. <strong>В началото не се интересувах от програмиране</strong> - цялото ми внимание беше фокусирано върху Blueprints и Unreal Engine. Но в <i>10-ти клас в училище</i> започнахме да имаме повече лекции и практически упражнения по програмиране и това беше момента, в който <strong>открих, че се забавлявам и съм погълнат от писането на код</strong >.
                </>
            }           
          </p>
          <p style={{textIndent: '30px'}} >
            { language === 'en' 
              ? <>
                  So, I paused my learning of Unreal Engine and focussed on developing something with code, not Blueprint like until now. I interacted with the Website Development, then with Mobile Apps Development - by creating MY own version of "Infinite Korean", using C# and Xamarin. After that, I decided to get to know Unity, because it was praised by many people and while the Unreal Engine, was offering C++ as a programming language to develop video games, Unity was using C#, which was already known language by me. That was when the "KPoP Quiz" game was created. I also created a shooter game with Unity. At 11th grade, in school we were studying websites, which was something that I have already explored, so in my personal study road, I started pushing Python. Then I returned to Unreal Engine, aimed to interact with C++. During my <i>12th grade</i>, the <strong><i>Artificial Intelligence</i></strong> started to raise up noise, and after finishing all exams and graduate the school, I started to learn AI, founding it really <strong>interesting, fun and passionately</strong>. 
                </>
              : <>
                  Така прекъснах изучаването на Unreal Engine и се съсредоточих върху разработването на нещо с код, а не върху Blueprint, както досега. Занимавах се с разработката на уебсайтове, след това с разработката на мобилни приложения - като създадох МОЯТА собствена версия на "Infinite Korean", използвайки C# и Xamarin. След това реших да се запозная с Unity, защото беше възхваляван от много хора и докато Unreal Engine предлагаше C++ като програмен език за разработване на видеоигри, Unity използваше C#, който вече ми беше познат език. Тогава създадох играта "KPoP Quiz". Създадох и шутър игра с Unity. В 11-ти клас в училище изучавахме уебсайтове, което беше нещо, което вече съм проучвал, така че в моя личен учебен път започнах да натискам Python. След това се върнах към Unreal Engine, с цел да разуча C++. По време на моя <i>12-ти клас</i> <strong><i>Изкуственият интелект</i></strong> започна да вдига шум и след като приключих с всички изпити и завърших училище, започнах да уча AI , намирайки го за наистина <strong>интересен, забавен и страстен</strong>.
                </>
            }
          </p>
          <p>
            { language === 'en' 
              ? <>
                  And that is how in 2024, I continue my education abroad - in <a href="https://www.jku.at/en" target="_blank" rel='noreferrer'>Johannes Kepler University</a>, in Linz, with Bachelor's major in <q>Artificial Intelligence</q>.
                </>
              : <>
                  И така през 2024, продължавам образованието си в чужбина - в <a href="https://www.jku.at/en" target="_blank" rel='noreferrer'>Johannes Kepler University </a>, в Линц, с бакалавърска специалност <q>Изкуствен интелект</q>.
                </>
            }
          </p>
          <p>
            { language === 'en' 
              ? <>
                  I am passionate about: <strong><i>Artificial Intelligence</i></strong> and <strong><i>Game Development</i></strong>.
                </>
              : <>
                  Аз съм запален по: <strong><i>Изкуствен интелект</i></strong> и <strong><i>Разработка на игри</i></strong>.
                </>
            }
          </p>
          <p>
            { language === 'en' 
              ? <>
                  Beside programming and video games, I am also a car lover and sportman, having an activity daily which helps me to stay motivated and inspired.
                </>
              : <>
                  Освен програмирането и видеоигрите, аз също съм любител на колите и спортист, ежедневно имам занимания, което ми помага да остана мотивиран и вдъхновен.
                </> 
            }
          </p>
          <p>
            { language === 'en' 
              ? <>
                  I am always eager to learn new things and take on new challenges.
                </>
              : <>
                  Винаги съм нетърпелив да научавам нови неща и да приемам нови предизвикателства.
                </>
            }
          </p>
          <p>
            { language === 'en' 
              ? <>
                  If you want to get in touch with me, use the <a href="/Contact">Contact page</a>. I am open to collaborations and new opportunities. Hope to work together!
                </>
              : <>
                  Ако искате да се свържете с мен, използвайте <a href="/Contact">страницата за контакт</a>. Отворен съм за сътрудничество и нови възможности. Надявам се да работим заедно!
                </>
            }
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
